import React, { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import Head from 'next/head'

import useUser from '@hooks/useUser';

const Home = () => {
  const router = useRouter();
  const { user } = useUser({ redirectIfFound: false })
  const [pageLoading, setPageLoading] = useState<boolean>(true);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (router) {
      const { query } = router;
      if (user) {
        if (user.token) {
          if (user.settings) {
            if (user.settings.livemode) {
              if (user.team) {
                if (user.team.activated) {
                  timer = setTimeout(() => {
                    router.replace({ pathname: '/dashboard', query });
                  }, 500)
                } else {
                  timer = setTimeout(() => {
                    router.replace({ pathname: '/test/dashboard', query });
                  }, 500)
                }
              } else {
                timer = setTimeout(() => {
                  router.replace({ pathname: '/test/dashboard', query });
                }, 500)
              }
            } else {
              timer = setTimeout(() => {
                router.replace({ pathname: '/test/dashboard', query });
              }, 500)
            }
          } else {
            router.replace({ pathname: '/login', query })
          }
        } else {
          router.replace({ pathname: '/login', query })
        }
      }
    }

    return () => {
      if (timer !== null) clearTimeout(timer); 
    }
  }, [router, user])

  return (
    <div className="antialiased">
      <Head>
        <title>Dashboard - AUB PayMate</title>
      </Head>
      {
        pageLoading &&
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="spinner-icon">
            <div className="w-6 h-6">
              <svg className="w-6 h-6" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" focusable="false">
                <ellipse className="origin-center animate-spinner-spin" cx="12" cy="12" rx="10" ry="10" style={{ stroke: 'rgb(156, 163, 175)', strokeWidth: 2 }}></ellipse>
              </svg>
            </div>
          </div>
        </div>
      }
    </div>
  )
}

export default Home;
